export * from './flags';
export * from './network';
export * from './numbers';
export * from './points';
export * from './timeAndDates';
export * from './tokens';
export * from './apy';

export const PERMISSION_LEVELS = {
  PRIVATE: 0,
  FUNCTION_LEVEL: 1,
  POOL_LEVEL: 2,
  PUBLIC: 3,
};

export const POLLING_INTERVAL = 15000; // 15 seconds
