import { Addresses } from 'Contracts/addresses/localhost';

const addresses: Addresses = {
  syrupTokenMigrator: '0x4325d437b189122a93a0b99b37effa22dcb81072',
  mpl: '0x46e33a8376a64d5293a5a4e785cbbee1eb205520',
  xMPL: '0xf3f0fb6d82181daba6baa592e07ba8da14425d13',
  mplUserActions: '0x4200000000000000000000000000000000000000',
};

export default addresses;
