import { PROJECT } from 'Constants/network';
import { Addresses } from 'Contracts/addresses/localhost';
import localhostAddresses from 'Contracts/addresses/localhost';
import mainnetProdAddresses from 'Contracts/addresses/mainnet-prod';
import sepoliaDevAddresses from 'Contracts/addresses/sepolia-dev';

const ADDRESSES_MAP: Record<string, Addresses> = {
  localhost: localhostAddresses,
  'mainnet-prod': mainnetProdAddresses,
  'sepolia-dev': sepoliaDevAddresses,
};

export const ADDRESSES = ADDRESSES_MAP[PROJECT];
