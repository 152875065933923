import { ReactNode, createContext, useEffect, useState, useMemo } from 'react';

// Hooks
import useIsMarketingPage from 'Hooks/useIsMarketingPage';
import useConvertPageLocation from 'Hooks/useConvertPageLocation';

// GraphQL
import { useIsRestrictedLazyQuery } from 'Graphql/schema';

export interface RestrictedContextType {
  isRestricted: boolean;
  setRestricted: (value: boolean) => void;
}
export const RestrictedContext = createContext<RestrictedContextType>({
  isRestricted: false,
  setRestricted: () => {},
});

type Props = {
  children: ReactNode;
};

export const RestrictedProvider = ({ children }: Props) => {
  const isMarketingPage = useIsMarketingPage();
  const isConvertPage = useConvertPageLocation();
  const [isRestricted, setRestricted] = useState(false);
  const [getIsRestricted, { error }] = useIsRestrictedLazyQuery();

  const isPublicPage = useMemo(() => isMarketingPage || isConvertPage, [isMarketingPage, isConvertPage]);

  useEffect(() => {
    if (!isPublicPage && !isRestricted && error?.graphQLErrors[0]?.extensions.code === 'RESTRICTED') {
      setRestricted(true);
    }
  }, [error, isRestricted, isPublicPage]);

  useEffect(() => {
    if (isPublicPage) {
      setRestricted(false);
    } else {
      getIsRestricted();
    }
  }, [isPublicPage, getIsRestricted]);

  return <RestrictedContext.Provider value={{ isRestricted, setRestricted }}>{children}</RestrictedContext.Provider>;
};
