import { Addresses } from 'Contracts/addresses/localhost';

const addresses: Addresses = {
  syrupTokenMigrator: '0x0000000000000000000000000000000000000000',
  mpl: '0x0000000000000000000000000000000000000000',
  xMPL: '0x0000000000000000000000000000000000000000',
  mplUserActions: '0x0000000000000000000000000000000000000000',
};

export default addresses;
