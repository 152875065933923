export const isReferralEnabled = process.env.REACT_APP_REFERRAL_ENABLED
  ? JSON.parse(process.env.REACT_APP_REFERRAL_ENABLED)
  : false;

export const isDripsEnabled = process.env.REACT_APP_DRIPS_ENABLED
  ? JSON.parse(process.env.REACT_APP_DRIPS_ENABLED)
  : false;

export const isConvertEnabled = process.env.REACT_APP_CONVERT_ENABLED
  ? JSON.parse(process.env.REACT_APP_CONVERT_ENABLED)
  : false;
