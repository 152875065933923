interface ColorScale {
  50: string;
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
}

interface NeutralColorScale extends ColorScale {
  0: string;
  950: string;
}

export const NEUTRAL: NeutralColorScale = {
  0: '#FFFFFF',
  50: '#F5F7FA',
  100: '#F2F5F8',
  200: '#E1E4EA',
  300: '#CACFD8',
  400: '#99A0AE',
  500: '#717784',
  600: '#525866',
  700: '#2B303B',
  800: '#222530',
  900: '#181B25',
  950: '#0E121B',
};

export const ORANGE: ColorScale = {
  50: '#FFF2EC',
  100: '#FEE6D9',
  200: '#FED9C6',
  300: '#F7BD9F',
  400: '#FF9F6E',
  500: '#FC8042',
  600: '#E17139',
  700: '#A35027',
  800: '#79391A',
  900: '#582811',
};

export const RED: ColorScale = {
  50: '#FBEDEC',
  100: '#F7DADA',
  200: '#F1B6B5',
  300: '#EC9391',
  400: '#E87470',
  500: '#F74242',
  600: '#D93939',
  700: '#C33333',
  800: '#9B2828',
  900: '#882525',
};

export const GREEN: ColorScale = {
  50: '#EEF9F2',
  100: '#DEF1E6',
  200: '#CDEBD9',
  300: '#BDE4CD',
  400: '#9DD7B3',
  500: '#3FC07C',
  600: '#32A367',
  700: '#2B8756',
  800: '#207548',
  900: '#1B613C',
};

export const YELLOW: ColorScale = {
  50: '#FDF1D6',
  100: '#FFF2EC',
  200: '#FAE2AF',
  300: '#F9DB9B',
  400: '#F8D588',
  500: '#FFB525',
  600: '#E9A420',
  700: '#DB9919',
  800: '#C98E1B',
  900: '#A87615',
};

export const BLUE: ColorScale = {
  50: '#D6E2FC',
  100: '#C2D3FB',
  200: '#ADC4FA',
  300: '#99B6F9',
  400: '#85A8F8',
  500: '#256FFF',
  600: '#1D60E3',
  700: '#184CB2',
  800: '#174296',
  900: '#0F2B61',
};

// To be added by design team
export const PURPLE = {
  500: '#6E44FF',
};

export const GRADIENT = {
  circularAndLinear:
    'radial-gradient(circle at -50px -50px, rgba(255,140,115,1) 0%, rgba(255,140,115,0) 60%) no-repeat,linear-gradient(180deg, #FB7A31 23.31%, #FF9F6E 81.41%) no-repeat, #FF9F6E',
  combinedLinears:
    'linear-gradient(249.65deg, #F87A31 0.53%, #FE9D66 98.49%) no-repeat, linear-gradient(294.87deg, rgba(255, 140, 115, 0) 42.34%, #FF8C73 117.04%) no-repeat',
  linearA: 'linear-gradient(98.41deg, #34108C 0%, #5113E8 50%, #DA93E5 100%)',
  linearB: 'linear-gradient(90deg, #ff7900 0%, #fdba2b 100%)',
  linearC:
    'linear-gradient(295deg, rgba(255, 140, 115, 0.00) 42.34%, #FF8C73 117.04%), linear-gradient(250deg, #F87A31 0.53%, #FE9D66 98.49%)',
};
