import { useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

// Router
import { Router } from 'Router';

// Hooks
import useIsMarketingPage from 'Hooks/useIsMarketingPage';
import useRestricted from 'Hooks/useRestricted';

// Components
import AppBarMarketing from 'Components/AppBarMarketing';
import AppBar from 'Components/AppBar';
import NavBar from 'Components/NavBar';
import FooterMarketing from 'Components/FooterMarketing';
import Footer from 'Components/Footer';

// Pages
import Error from 'Pages/Error/Error';

const PageContainer = () => {
  const isMarketingPage = useIsMarketingPage();
  const { isRestricted } = useRestricted();

  const backgroundStyles = useMemo(() => {
    if (isRestricted)
      return {
        background: theme => ({ xs: theme.palette.gradient.circularAndLinear, md: 'none' }),
        backgroundSize: '200px 200px, cover, cover',
        backgroundPosition: 'top left, center, center',
        backgroundRepeat: 'no-repeat, no-repeat, no-repeat',
      };
    return {};
  }, [isRestricted]);

  return (
    <Box
      sx={{
        backgroundColor: isMarketingPage ? 'transparent' : 'background.weak',
        margin: '0 auto',
        overflow: 'hidden',
        width: '100vw',
        minHeight: '100vh',
      }}
    >
      <>
        {isMarketingPage ? (
          <AppBarMarketing />
        ) : (
          <>
            <AppBar />
            <NavBar />
          </>
        )}

        <ErrorBoundary FallbackComponent={Error}>
          <Stack
            sx={
              isMarketingPage
                ? {}
                : {
                    ...backgroundStyles,
                    pt: { xs: 12, md: 16 },
                    mb: 4,
                    minHeight: theme => ({
                      xs: `calc(100vh - ${theme.spacing(26)})`,
                      md: `calc(100vh - ${theme.spacing(13)})`,
                    }),
                  }
            }
          >
            <Router />
          </Stack>
        </ErrorBoundary>

        {isMarketingPage ? <FooterMarketing /> : <Footer />}
      </>
    </Box>
  );
};

export default PageContainer;
