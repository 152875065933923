import React, { createContext, useEffect, useState } from 'react';
import { AnalyticsBrowser, ID } from '@segment/analytics-next';

export interface AnalyticsContextType {
  analytics: AnalyticsBrowser | null;
  initialized: boolean;
  anonymousId: ID;
}
export const AnalyticsContext = createContext<AnalyticsContextType>({
  analytics: null,
  initialized: false,
  anonymousId: null,
});

type Props = {
  writeKey: string;
  children: React.ReactNode;
};

export const AnalyticsProvider = ({ children, writeKey }: Props) => {
  const [initialized, setInitialized] = useState(false);
  const [anonymousId, setAnonymousId] = useState<ID>(null);
  const [analytics, setAnalytics] = useState<AnalyticsBrowser | null>(null);

  useEffect(() => {
    const initializeAnalytics = async () => {
      // Lazy load the AnalyticsBrowser
      const { AnalyticsBrowser } = await import('@segment/analytics-next');
      const analyticsInstance = AnalyticsBrowser.load({ writeKey });
      setAnalytics(analyticsInstance);

      analyticsInstance.ready().then(async () => {
        const id = (await analyticsInstance.user()).anonymousId();
        setAnonymousId(id);
        setInitialized(true);
      });
    };

    if (document.readyState === 'complete') {
      initializeAnalytics();
    } else {
      const loadHandler = () => {
        window.removeEventListener('load', loadHandler);
        initializeAnalytics();
      };
      window.addEventListener('load', loadHandler);
    }

    return () => {
      window.removeEventListener('load', initializeAnalytics);
    };
  }, [writeKey]);

  return (
    <AnalyticsContext.Provider value={{ analytics, initialized, anonymousId }}>{children}</AnalyticsContext.Provider>
  );
};
