import { Address } from 'viem';

type Contracts = 'mpl' | 'mplUserActions' | 'syrupTokenMigrator' | 'xMPL';
export type Addresses = Record<Contracts, Address>;

const addresses: Addresses = {
  syrupTokenMigrator: '0xdbbe7052e665eccb18d62eb4488914fe76d73dfc',
  mpl: '0x10e5eeca17bce71ce113be10543f377e426f8873',
  xMPL: '0xeb5030ed8ccb819bdf6c1eb2e2c4d9e86e7721fc',
  mplUserActions: '0x76352b42cf762583537cc01ec98ca351bccd7f3b',
};

export default addresses;
