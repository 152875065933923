import { Container, Stack, Box, Divider } from '@mui/material';

// Components
import MapleTypography from 'Components/ds/MapleTypography';
import MapleIcon from 'Components/ds/MapleIcon';
import MapleButton from 'Components/ds/MapleButton';

const wrapperStyles = {
  background: theme => ({ xs: theme.palette.gradient.circularAndLinear, md: 'none' }),
  backgroundSize: '200px 200px, cover, cover',
  backgroundPosition: 'top left, center, center',
  backgroundRepeat: 'no-repeat, no-repeat, no-repeat',
  pt: { xs: 12, md: 16 },
  mb: 4,
  minHeight: theme => ({
    xs: `calc(100vh - ${theme.spacing(26)})`,
    md: `calc(100vh - ${theme.spacing(13)})`,
  }),
};

const containerStyles = {
  flex: '1',
};

const bottleStyles = {
  width: '90px',
  height: '90px',
  backgroundColor: theme => theme.palette.background.white,
  borderRadius: theme => theme.shape.round,
};

const stackStyles = {
  minHeight: theme => ({ xs: `calc(100vh - ${theme.spacing(38)})`, md: `calc(100vh - ${theme.spacing(32)})` }),
  background: theme => ({ xs: 'none', md: theme.palette.gradient.circularAndLinear }),
  backgroundSize: '200px 200px, cover, cover',
  backgroundPosition: 'top left, center, center',
  backgroundRepeat: 'no-repeat, no-repeat, no-repeat',
  borderRadius: theme => theme.shape.xxl,
  position: 'relative',
  overflow: 'visible',
  p: theme => ({ xs: theme.spacing(0, 0, 2, 0), md: theme.spacing(2, 0, 2, 0) }),
};

const widthStyles = { width: { xs: '90%', sm: '70%', md: '50%', lg: '40%' } };

const ErrorPage = () => {
  return (
    <Stack sx={wrapperStyles}>
      <Container maxWidth='lg' sx={containerStyles}>
        <Stack sx={stackStyles} direction='column' justifyContent='center' alignItems='center' spacing={3}>
          <Stack alignItems='center' justifyContent='center' sx={bottleStyles}>
            <MapleIcon icon='syrupBottleLow' size={50} />
          </Stack>

          <MapleTypography variant='h1' color='text.white' textAlign='center' sx={widthStyles}>
            Whoops there is no Syrup here!
          </MapleTypography>
          <Box sx={widthStyles}>
            <Divider color='white' />
          </Box>
          <MapleTypography size='large' color='text.white' textAlign='center' sx={widthStyles}>
            We are currently experiencing some technical issues or undergoing an update, please try again later.
          </MapleTypography>
          <MapleButton color='secondary' href='/' opacity={0.5}>
            Go back home
          </MapleButton>
        </Stack>
      </Container>
    </Stack>
  );
};

export default ErrorPage;
